module.exports = {
  // 里石门
  1545: {
    stcd: "1545",
    stnm: "里石门(雨量1)",
    pos: {
      //  x,y 轴  tpos提示版位置r右l左
      tpos: "r",
      x: 635,
      y: 526,
    },
  },
  1544: {
    stcd: "1544",
    stnm: "田芯（雨量1）",
    pos: {
      tpos: "l",
      x: 553,
      y: 559,
    },
  },
  3600: {
    stcd: "3600",
    stnm: "四协",
    pos: {
      tpos: "r",
      x: 409,
      y: 320,
    },
  },
  3601: {
    stcd: "3601",
    stnm: "下田",
    pos: {
      tpos: "r",
      x: 130,
      y: 310,
    },
  },
  3602: {
    stcd: "3602",
    stnm: "柘溪",
    pos: {
      tpos: "r",
      x: 278,
      y: 519,
    },
  },
  3603: {
    stcd: "3603",
    stnm: "寺加坑",
    pos: {
      tpos: "r",
      x: 635,
      y: 265,
    },
  },
  // 钦寸
  1598: {
    stcd: "1598",
    stnm: "钦寸",
    pos: {
      tpos: "r",
      x: 50,
      y: 580,
    },
  },
  30008345: {
    stcd: "30008345",
    stnm: "董村",
    pos: {
      tpos: "r",
      x: 418,
      y: 405,
    },
  },
  1601: {
    stcd: "1601",
    stnm: "蔡峰",
    pos: {
      tpos: "r",
      x: 663,
      y: 590,
    },
  },
  1602: {
    stcd: "1602",
    stnm: "沙溪",
    pos: {
      tpos: "r",
      x: 452,
      y: 590,
    },
  },
  30008346: {
    stcd: "30008346",
    stnm: "里家溪",
    pos: {
      tpos: "l",
      x: 386,
      y: 201,
    },
  },
  8368: {
    stcd: "8368",
    stnm: "高家田",
    pos: {
      tpos: "l",
      x: 660,
      y: 605,
    },
  },
  1607: {
    stcd: "1607",
    stnm: "巧英水库",
    pos: {
      tpos: "l",
      x: 532,
      y: 142,
    },
  },
  2842: {
    stcd: "2842",
    stnm: "雪头",
    pos: {
      tpos: "l",
      x: 636,
      y: 203,
    },
  },
  3913: {
    stcd: "3913",
    stnm: "庵庙前水库",
    pos: {
      tpos: "r",
      x: 227,
      y: 526,
    },
  },
  3915: {
    stcd: "3915",
    stnm: "上徐",
    pos: {
      tpos: "r",
      x: 506,
      y: 526,
    },
  },
  3916: {
    stcd: "3916",
    stnm: "五星",
    pos: {
      tpos: "r",
      x: 674,
      y: 268,
    },
  },
  5502: {
    stcd: "5502",
    stnm: "巧王水库",
    pos: {
      tpos: "l",
      x: 563,
      y: 202,
    },
  },
  5503: {
    stcd: "5503",
    stnm: "西桥弄水库",
    pos: {
      tpos: "r",
      x: 209,
      y: 345,
    },
  },
  5506: {
    stcd: "5506",
    stnm: "棠家洲水库",
    pos: {
      tpos: "l",
      x: 321,
      y: 454,
    },
  },
  5507: {
    stcd: "5507",
    stnm: "竺家坑水库",
    pos: {
      tpos: "r",
      x: 704,
      y: 525,
    },
  },
  8171: {
    stcd: "8171",
    stnm: "溪下坝下",
    pos: {
      tpos: "r",
      x: 742,
      y: 488,
    },
  },
  8172: {
    stcd: "8172",
    stnm: "云洲",
    pos: {
      tpos: "r",
      x: 225,
      y: 536,
    },
  },
  8173: {
    stcd: "8173",
    stnm: "毛山头",
    pos: {
      tpos: "r",
      x: 486,
      y: 261,
    },
  },
  //横山
  1806: {
    stcd: "1806",
    stnm: "柏坑水库",
    pos: {
      tpos: "r",
      x: 429,
      y: 370,
    },
  },
  1807: {
    stcd: "1807",
    stnm: "南溪口",
    pos: {
      tpos: "r",
      x: 564,
      y: 453,
    },
  },
  1809: {
    stcd: "1809",
    stnm: "寺坑",
    pos: {
      tpos: "r",
      x: 442,
      y: 575,
    },
  },
  1850: {
    stcd: "1850",
    stnm: "董家雨量站",
    pos: {
      tpos: "r",
      x: 335,
      y: 217,
    },
  },
  1852: {
    stcd: "1852",
    stnm: "箭岭下雨量",
    pos: {
      tpos: "r",
      x: 405,
      y: 472,
    },
  },
  1901: {
    stcd: "1901",
    stnm: "横山水库雨量",
    pos: {
      tpos: "r",
      x: 631,
      y: 552,
    },
  },
  // 泽洋
  14771: {
    stcd: "14771",
    stnm: "北林垟",
    pos: {
      tpos: "r",
      x: 330,
      y: 447,
    },
  },
  14770: {
    stcd: "14770",
    stnm: "五凤垟",
    pos: {
      tpos: "r",
      x: 333,
      y: 269,
    },
  },
  14772: {
    stcd: "14772",
    stnm: "泽雅",
    pos: {
      tpos: "r",
      x: 603,
      y: 361,
    },
  },
  // 亭下
  1800: {
    stcd: "1800",
    stnm: "亭下",
    pos: {
      tpos: "r",
      x: 736,
      y: 437,
    },
  },
  1802: {
    stcd: "1802",
    stnm: "董家彦",
    pos: {
      tpos: "r",
      x: 120,
      y: 538,
    },
  },
  1830: {
    stcd: "1830",
    stnm: "东岙",
    pos: {
      tpos: "r",
      x: 396,
      y: 412,
    },
  },
  1803: {
    stcd: "1803",
    stnm: "升纲",
    pos: {
      tpos: "r",
      x: 564,
      y: 367,
    },
  },
  1804: {
    stcd: "1804",
    stnm: "栖霞坑",
    pos: {
      tpos: "r",
      x: 520,
      y: 569,
    },
  },
  1803: {
    stcd: "1803",
    stnm: "升纲",
    pos: {
      tpos: "r",
      x: 564,
      y: 367,
    },
  },
  1831: {
    stcd: "1831",
    stnm: "班竹石门",
    pos: {
      tpos: "r",
      x: 724,
      y: 603,
    },
  },
  // 青山
  1369: {
    stcd: "1369",
    stnm: "太湖源-南庄",
    pos: {
      tpos: "r",
      x: 183,
      y: 429,
    },
  },
  1367: {
    stcd: "1367",
    stnm: "太湖源-溪口",
    pos: {
      tpos: "r",
      x: 287,
      y: 540,
    },
  },
  1368: {
    stcd: "1368",
    stnm: "太湖源-市岭",
    pos: {
      tpos: "r",
      x: 288,
      y: 650,
    },
  },
  1375: {
    stcd: "1375",
    stnm: "玲珑-徐家头",
    pos: {
      tpos: "r",
      x: 398,
      y: 159,
    },
  },
  2512: {
    stcd: "2512",
    stnm: "锦城-桥东村雨量",
    pos: {
      tpos: "r",
      x: 399,
      y: 364,
    },
  },
  3381: {
    stcd: "3381",
    stnm: "临安-临安",
    pos: {
      tpos: "r",
      x: 534,
      y: 298,
    },
  },
  1371: {
    stcd: "1371",
    stnm: "板桥-三口",
    pos: {
      tpos: "r",
      x: 660,
      y: 78,
    },
  },
  1376: {
    stcd: "1376",
    stnm: "青山水库雨量",
    pos: {
      tpos: "r",
      x: 661,
      y: 293,
    },
  },// 南江
  16286: {
    stcd: "16286",
    stnm: "山店",
    pos: {
      tpos: "r",
      x: 290,
      y: 360,
    },
  },
  2242: {
    stcd: "2242",
    stnm: "南江",
    pos: {
      tpos: "r",
      x: 151,
      y: 235,
    },
  },
  32331: {
    stcd: "32331",
    stnm: "深泽(花溪)",
    pos: {
      tpos: "r",
      x: 726,
      y: 171,
    },
  },
  6163: {
    stcd: "6163",
    stnm: "花溪电站",
    pos: {
      tpos: "r",
      x: 624,
      y: 389,
    },
  },
  7796: {
    stcd: "7796",
    stnm: "安文雨量",
    pos: {
      tpos: "r",
      x: 512,
      y: 239,
    },
  },
  // 百丈及
  2287: {
    stcd: "2287",
    stnm: "十源",
    pos: {
      tpos: "r",
      x: 617,
      y: 416,
    },
  },
  2458: {
    stcd: "2458",
    stnm: "坝上雨量",
    pos: {
      tpos: "r",
      x: 468,
      y: 106,
    },
  },
  2952: {
    stcd: "2952",
    stnm: "南田",
    pos: {
      tpos: "r",
      x: 158,
      y: 456,
    },
  },
  30003177: {
    stcd: "30003177",
    stnm: "朱阳",
    pos: {
      tpos: "r",
      x: 515,
      y: 283,
    },
  },
  3178: {
    stcd: "3178",
    stnm: "胡垟",
    pos: {
      tpos: "r",
      x: 296,
      y: 256,
    },
  }
};
